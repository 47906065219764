import type { GetStaticPaths } from 'next';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { optiDigitalCancelInfiniteLoad } from '../../../../front/src/components/Ads/OptiDigitalAds';
import { insertLayoutData } from '../../../../front/src/contributor/api/layout';
import { Block } from '../../../../front/src/contributor/Layout/Block';
import { useDataExposer } from '../../../../front/src/hooks/useExposedData';
import { useWebsiteMeta } from '../../../../front/src/hooks/useWebsite';
import { handleGqlError } from '../../../../front/src/services/graphql/gql-utils';
import { makeHomeTopicsVariables } from '../../../../front/src/thread/api/topic';
import type { QueryType } from '../../../../front/src/types/api';
import type { LayoutBlock, PageType } from '../../../../front/src/types/layout';
import { MetaPage } from '../../components/Meta/MetaPage';
import type { GetStaticPropsGD } from '../../utils/getStaticPropsCommon';
import {
  defaultStaticPathsNoPreRender,
  getStaticPropsCommon,
} from '../../utils/getStaticPropsCommon';
import { checkWebsiteIsVIP } from '../../utils/middleware-utils';

const pageName = 'homepage';

export const homepageMessages = defineMessages({
  homePageName: { defaultMessage: "Page d'accueil", id: 'ue0MU+' },
});

export interface HomeProps {
  initialVariables: ReturnType<typeof makeHomeTopicsVariables>;
  websiteIsVIP: boolean;
  contentAreaLayout: LayoutBlock[];
  initialData?: QueryType;
}

export const Home: PageType<HomeProps> = props => {
  const meta = useWebsiteMeta(pageName);
  const { formatMessage } = useIntl();
  const dataExposer = useDataExposer();

  const { contentAreaLayout } = props;

  // const forceInitialFetch = initialVariables.limit !== limit || initialVariables.sort !== sort;

  // const { error, loading, refetch, initialData } = useQueryCallback({
  //   query: headingsQuery,
  //   initialData: headings,
  //   forceInitialFetch: false,
  //   onResponse: updateReduxState,
  //   // onError: cleanUpComponent, // The error itself is handled by the returned error, with ErrorBlock
  // });

  // useOnCachedTokenReady(hasToken => {
  //   if (hasToken) {
  //     refetch();
  //   }
  // });

  // useCheckSSR(loading && !forceInitialFetch && !websiteIsVIP);

  useEffect(() => {
    optiDigitalCancelInfiniteLoad();
    dataExposer.setDataExposed({
      pageName: 'home',
    });
  }, [dataExposer]);

  // // Refetch the data when the user navigates back to the home page
  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     if (asPath === '/' || asPath.startsWith('/?')) {
  //       refetch();
  //     }
  //   };

  //   events.on('routeChangeComplete', handleRouteChange);

  //   // Unsubscribe from the event when the component unmounts
  //   return () => {
  //     events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [events, asPath, refetch]);

  // if (error) {
  //   return <ErrorBlock error={error} />;
  // }

  // if (loading && !initialData) {
  //   return <LoaderBlock />;
  // }

  const layoutContent = contentAreaLayout?.map(block => <Block key={block?._id} block={block} />);

  const title = `${formatMessage(homepageMessages.homePageName)} - {websiteName}`;

  return (
    <>
      <MetaPage title={title} canonicalPath="/" {...(meta?.homepage ?? {})} index follow />
      <div className="Home flex flex-col md:flex-row gap-10">
        <div className="flex-1 flex flex-col space-y-5 max-w-full">
          {/* <LayoutContentToSpread /> */}
          {layoutContent}
        </div>
      </div>
    </>
  );
};

// See packages/graphdebate/src/pages/[website]/t/[slug].tsx for getStaticProps instructions
// export const getServerSideProps: GetServerSidePropsGD = async context => {
export const getStaticProps: GetStaticPropsGD<HomeProps> = async context => {
  // Common code
  let { response, gqlClient, website } = await getStaticPropsCommon(context, Home);

  if ('redirect' in response || 'notFound' in response) {
    return response;
  }

  const { customLayout, headings, ...otherProps } = response.props;

  const query = (context.params || {}) as any;
  const graphdebate = website.graphdebate!;
  const websiteIsVIP = checkWebsiteIsVIP(website);

  const variables = makeHomeTopicsVariables(graphdebate, query);

  const homeCustomLayout = [...customLayout];

  try {
    const layoutWithData = await insertLayoutData(gqlClient, homeCustomLayout, headings);

    response.props = {
      ...otherProps,
      customLayout: layoutWithData,
      headings,
      pageConfig: { previousPage: null },
      // headingsAndTopics: data,
      initialVariables: variables,
      websiteIsVIP,
      // layoutData,
      // initialData,
    };

    return response;
  } catch (error: any) {
    handleGqlError(error);
    throw error;
  }
};

export const getStaticPaths: GetStaticPaths = defaultStaticPathsNoPreRender;

Home.pageName = () => pageName;

export default Home;
